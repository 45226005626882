@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .section-min-height {
      min-height: calc(100vh - 68px)
  }
}

@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap')